<template>
<div>
  <nav-bar></nav-bar>
  <div class="container-fluid page-body-wrapper">
  <side-bar></side-bar>
  <!-- partial -->
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="page-header">
        <h4 class="page-title">Subscription Plans</h4>
      </div>
      <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body nw-opd">
              <div class="row me-1">
                 <div class="col-lg-4">
                    <input placeholder="Search" v-model="searchtext" class="form-control" type="search" name="searchtext" id="searchtext" autocomplete="off" v-on:keyup="getSubscriptionsPlanList(1)">
                 </div>
                 <h4 class="col-lg-1"></h4>
                <div class="col-lg-2">
                    <multiselect
                        v-model="filter_status"
                        :options="status_list"
                        :multiple="false"
                        :close-on-select="true"
                        :clear-on-select="false"
                        :preserve-search="true" placeholder="Status"
                        :preselect-first="true"
                        :showLabels="false"
                        :allowEmpty="false"
                        @input=getSubscriptionsPlanList(1)
                    /></multiselect>
                </div>
                <div class="col-lg-2">
                    <multiselect
                        v-model="filter_auto_renewal"
                        label="name"
                        :options="status_auto_renewal_list"
                        :multiple="false"
                        :close-on-select="true"
                        :clear-on-select="false"
                        :preserve-search="true" placeholder="Auto renewal"
                        :preselect-first="true"
                        :showLabels="false"
                        :allowEmpty="false"
                        @input=getSubscriptionsPlanList(1)
                    /></multiselect>
                </div>
                 <router-link to="/master-admin/add-subscription-plan" type="button" class="col-lg-3 btn btn-gradient-primary">Add Subscription Plan</router-link>
              </div>
              <div class="table-responsive">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Plan Name</th>
                        <th>Plan Price(USD)</th>
                        <th>Plan Discounted<br/>Price(USD)</th>
                        <th>Plan Validity<br/>(Days)</th>
                        <th>Is Auto<br/>Renewal</th>
                        <th>Support in<br/>Time(Days)</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(obj,i) in subscription_plans" :key="i">
                        <td>{{obj.id}}</td>
                        <td>{{obj.plan_name}}</td>
                        <td>${{obj.plan_price}}</td>
                        <td>${{obj.plan_discounted_price}}</td>
                        <td>{{obj.plan_validity}} Days</td>
                        <td>{{getAutoRenewalText(obj.is_auto_renewal)}}</td>
                        <td>{{obj.support_in_time}}</td>
                        <td><label v-bind:class="{'badge badge-success': obj.status === 'Active',
                                           'badge badge-danger': obj.status === 'InActive' ||
                                           obj.status === 'Inactive'}"
                                           @click="updateSubscriptionPlanStatus(obj.id, obj.status, i)">{{obj.status}}</label></td>
                        <td><router-link :to="{name: 'master-admin-add-subscription-plan',params: { id: obj.id}}" type="button" >
                            <button type="button" class="btn btn-gradient-primary btn-rounded btn-icon"><i class="mdi mdi-eye"></i></button></router-link>
                            &nbsp&nbsp
                            <button type="button" @click="deleteSubscriptionPlan(obj.id, obj.plan_name, i)"class="btn btn-gradient-primary btn-rounded btn-icon"><i class="mdi mdi-delete"></i></button></router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
              </div>
              <div v-if="is_API_call_running" class="text-center mt-5">
                <b-spinner label=""></b-spinner>
              </div>
              <div class="row" v-if="!is_API_call_running && subscription_plans.length == 0" >
                  <div class="col-lg-12 text-center">
                    <br/><br/><br/>
                    <small class="text-muted">{{ errorMessage }}</small>
                    <br/><br/><br/>
                  </div>
              </div>
              <div class="b-pagination-outer" v-if="listPages > 1">
                <ul id="border-pagination">
                  <li><a href="javascript:void(0)" @click="previewClick()">«</a></li>
                  <li v-for="page in listPages" :key="page"><a href="javascript:void(0)" @click="getSubscriptionsPlanList(page)" :class="(activePage && activePage==page)?'active':''">{{page}}</a></li>
                  <li><a href="javascript:void(0)" @click="nextClick()">»</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</div>
</div>
</template>
<script>
import NavBar from "@/components/NavBarMaster.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBarMaster.vue";
import Multiselect from 'vue-multiselect';
import { mapActions, mapGetters } from "vuex";
import string from "../../../constant/strings.js"
import Swal from 'sweetalert2'

export default {
  name:'subscription-plans',
  title: string.PAGE_TITLE_SUBSCRIPTIONSro,
  components: {
      NavBar,
      Footer,
      SideBar,
      Multiselect
  },
  data() {
    return {
      subscription_plans:[],
      is_API_call_running: false,
      listPages:0,
      activePage:1,
      searchtext:'',
      myTimer:null,
      errorMessage : 'No Plans Found.',
      status_list:['All','Active', 'Inactive'],
      status_auto_renewal_list:[{"id":"All", "name":"All"}, {"id":"1", "name":"Yes"}, {"id":"0", "name":"No"}],
      filter_status: "All",
      filter_auto_renewal: {"id":"All", "name":"All"},
    }
  },
  mounted() {
    this.getSubscriptionsPlanList(this.activePage);
  },
  methods:{
    ...mapActions("hospital",["getSubscriptionPlanListAPI", "updateSubscriptionPlanStatusAPI", "deleteSubscriptionPlanAPI"]),

    getSubscriptionsPlanList(page){
      this.activePage = page;
      clearTimeout(this.myTimer)
      this.myTimer = setTimeout(() => {
        this.is_API_call_running = true;
        this.subscription_plans = [];
        var bodyFormData = new FormData();
        bodyFormData.append('filter_value', this.searchtext);
        bodyFormData.append('page', page);
        bodyFormData.append('filter_status', this.filter_status);
        bodyFormData.append('filter_auto_renewal', this.filter_auto_renewal.id);
        this.getSubscriptionPlanListAPI(bodyFormData).then((response) => {
            this.is_API_call_running = false
            if (response.response_code == 200) {
            this.subscription_plans = response.data;
            this.listPages = response.no_of_pages;
            }else{
              this.errorMessage = response.message;
            }
        });
      }, 500)
    },

    updateSubscriptionPlanStatus(plan_id, obj_status, index){
      var bodyFormData = new FormData();
      bodyFormData.append('plan_id', plan_id);
      if(obj_status == "Active") {
        bodyFormData.append('status', "Inactive");
      } else {
        bodyFormData.append('status', "Active");
      }
      this.updateSubscriptionPlanStatusAPI(bodyFormData).then((response) => {
            if (response.response_code == 200) {
                this.subscription_plans.splice(index, 1, response.data);
            }else{
                this.errorMessage = response.message;
            }
      });
    },

    previewClick(){
          this.activePage--;
          if(this.activePage >= 1){
            this.getSubscriptionsPlanList(this.activePage);
          }else{
            this.activePage = 1;
          }
        },

    nextClick(){
       this.activePage++;
       if(this.activePage <= this.listPages){
            this.getSubscriptionsPlanList(this.activePage);
       }else{
            this.activePage = this.listPages;
       }
    },
    getAutoRenewalText(is_auto_renewal) {
        if(is_auto_renewal) {
            return "Yes"
        } else {
            return "No"
        }
    },

    deleteSubscriptionPlan(id, plan_name, i) {
        Swal.fire({
          title: string.LABEL_ARE_YOU_SURE,
          text: string.ALERT_MESSAGE_DELETE_PRE + plan_name + string.SUBSCRIPTION_PLAN,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!"
        }).then((result) => {
          if (result.isConfirmed) {
            var bodyFormData = new FormData();
            bodyFormData.append('plan_id', id);
            this.deleteSubscriptionPlanAPI(bodyFormData).then((response) => {
                if (response.response_code == 200) {
                    this.$toasted.success(response.message, {duration: 2000,});
                    for (let i = 0; i <= this.subscription_plans.length;i++){
                        if(id == this.subscription_plans[i].id) {
                            this.subscription_plans.splice(i, 1);
                            break;
                        }
                    }
                } else{
                    this.$toasted.error(response.message, {duration: 2000,});
                }
            });
          }
        });
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>