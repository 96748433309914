<template>
  <nav class="sidebar sidebar-offcanvas" id="sidebar">
    <ul class="nav">
      <li class="nav-item nav-profile">
        <router-link to="/admin-profile" class="nav-link">
          <div class="nav-profile-image d-flex flex-column">
            <span class="font-weight-bold mb-2">{{ $auth.user.name }}</span>
            <p>{{ $auth.user.role }}</p>
          </div>
          <i class="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
        </router-link>
      </li>
      <li class="nav-item" :class="$route.name == 'masteradmin' ? 'active' : ''">
        <router-link to="/master-admin" class="nav-link">
          <i class="mdi mdi-home menu-icon"></i>
          <span class="menu-title">Dashboard</span>
        </router-link>
      </li>
      <li class="nav-item"
        :class="$route.name == 'master-admin-subscription-plans' || $route.name == 'master-admin-add-subscription-plan' ? 'active' : ''">
        <router-link to="/master-admin/subscription-plans" class="nav-link">
          <i class="mdi mdi mdi-buffer menu-icon"></i>
          <span class="menu-title">Subscription Plans</span>
        </router-link>
      </li>
      <li class="nav-item" :class="this.$route.name == 'master-admin-clients' ||
                this.$route.name == 'master-admin-client-details' ||
                this.$route.name == 'master-admin-client-user-details' ? 'active' : ''">
        <router-link to="/master-admin/clients" class="nav-link">
          <i class="mdi mdi mdi-account-multiple menu-icon"></i>
          <span class="menu-title">Clients</span>
        </router-link>
      </li>
      <li class="nav-item" :class="this.$route.name == 'master-admin-currencies' ? 'active' : ''">
        <router-link to="/master-admin/currencies" class="nav-link">
          <i class="mdi mdi mdi-currency-inr menu-icon"></i>
          <span class="menu-title">Currencies</span>
        </router-link>
      </li>
      <li class="nav-item" :class="this.$route.name == 'master-admin-transactions' ? 'active' : ''">
        <router-link to="/master-admin/transactions" class="nav-link">
          <i class="mdi mdi mdi-book-plus menu-icon"></i>
          <span class="menu-title">Payment Transactions</span>
        </router-link>
      </li>
      <li class="nav-item" :class="this.$route.name == 'changelogs' ? 'active' : ''">
        <router-link to="/statistics" class="nav-link">
          <i class="mdi mdi-account-multiple menu-icon"></i>
          <span class="menu-title">Sub Admin</span>
        </router-link>
      </li>
      <li class="nav-item" :class="this.$route.name == 'changelogs' ? 'active' : ''">
        <router-link to="/statistics" class="nav-link">
          <i class="mdi mdi mdi-menu menu-icon"></i>
          <span class="menu-title">Changelogs</span>
        </router-link>
      </li>
      <li class="nav-item nav-logout d-lg-none">
        <a class="nav-link" href="javascript:void(0)" @click="LogOut()">
          <i class="mdi mdi-power menu-icon"></i> Logout
        </a>
      </li>
    </ul>
  </nav>
</template>
<script>

import { mapActions, mapGetters } from "vuex";
import Cookies from 'js-cookie';

export default {

  methods:{
    ...mapActions("hospital",["logoutAdmin"]),
    LogOut() {
      if (confirm("Are you sure you want to logout?")) {
        const storedDeviceId = Cookies.get('device_id');
        let logoutData = {
          device_id: storedDeviceId,
        };
        this.logoutAdmin(logoutData).then((response) => {
            if (response.response_code == 200) {
                let logoutData = {
                  user_id: this.$auth.user.user_id,
                  devicetoken: this.$auth.user.token,
                  device_id: storedDeviceId,
                }
                this.$auth.logout(logoutData,(callback)=>{
                  this.$router.push({ name: callback }).catch(()=>{});
                });
            }else{
                this.$toasted.error(response.message, {duration: 2000,});
            }
        });
      }
    },
 }
}
</script>